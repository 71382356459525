import { type FC } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'

const AvatarLoading: FC<{ className?: string }> = ({ className }) => {
  return (
    <span
      className={`rounded-full border border-lottoblue-600 bg-black/20 flex items-center justify-center ${className ?? ''}`}
    >
      <AiOutlineLoading
        aria-hidden="true"
        className="w-5 h-5 text-white animate-spin"
        role="img"
      />
    </span>
  )
}

export default AvatarLoading
