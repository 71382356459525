import PubNub from 'pubnub'

export interface INotifications {
  potSizeChanged: (newAmount: number) => Promise<void>
}

export interface INotificationReceiver {
  on<TNotificationName extends keyof INotifications>(
    event: TNotificationName,
    listener: (
      ...args: Parameters<INotifications[TNotificationName]>
    ) => Promise<void>
  ): void
}

export class PubNubNotificationReceiver implements INotificationReceiver {
  private pubnub: PubNub
  private listeners: Partial<
    Record<keyof INotifications, Array<(...args: any[]) => Promise<void>>>
  > = {}

  constructor(subscribeKey: string) {
    this.pubnub = new PubNub({
      subscribeKey,
      userId: PubNub.generateUUID(),
    })

    const channel = this.pubnub.channel('potSizeChanged')
    const subscription = channel.subscription()

    subscription.onMessage = (m) => {
      const payload = m.message
      const potSizeChangedPayload = this.asPotSizeChangedPayload(payload)
      if (potSizeChangedPayload) {
        this.emit('potSizeChanged', potSizeChangedPayload.newAmount)
      }
    }

    subscription.subscribe()
  }

  on<TNotificationName extends keyof INotifications>(
    notification: TNotificationName,
    listener: (
      ...args: Parameters<INotifications[TNotificationName]>
    ) => Promise<void>
  ): void {
    const existingListeners: Array<INotifications[TNotificationName]> =
      this.listeners[notification] ?? []
    existingListeners.push(listener as any)
    this.listeners[notification] = existingListeners
  }

  off<TNotificationName extends keyof INotifications>(
    notification: TNotificationName,
    listener: (
      ...args: Parameters<INotifications[TNotificationName]>
    ) => Promise<void>
  ): void {
    const existingListeners = this.listeners[notification]
    if (!existingListeners) {
      return
    }
    const index = existingListeners.indexOf(listener as any)
    if (index !== -1) {
      existingListeners.splice(index, 1)
    }
  }

  private async emit<TNotificationName extends keyof INotifications>(
    event: TNotificationName,
    ...args: Parameters<INotifications[TNotificationName]>
  ): Promise<void> {
    const listeners = this.listeners[event]
    if (!listeners) {
      return
    }
    const results = await Promise.allSettled(
      listeners.map((listener) => listener(...args))
    )

    for (const result of results) {
      if (result.status === 'rejected') {
        console.error(result.reason)
      }
    }
  }

  private asPotSizeChangedPayload(payload: unknown) {
    if (!(payload && typeof payload === 'object')) {
      return undefined
    }

    if (!('newAmount' in payload)) {
      return undefined
    }

    const newAmount = payload.newAmount

    if (typeof newAmount !== 'number') {
      return undefined
    }

    return { newAmount }
  }
}

const pubnubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY ?? ''

export const globalNotifications = new PubNubNotificationReceiver(
  pubnubSubscribeKey
)
