import React, { useState } from 'react';
import { IDrawingEntry } from 'src/types';
import UserWins from './UserWins';
import UserEntries from './UserEntries';

type TabOption = 'upcoming' | 'results';

const UserResults: React.FC<{
  currentEntries: IDrawingEntry[],
  drawId: number,
  endDate: Date,
}> = ({ currentEntries, drawId, endDate }) => {
  
  const [activeTab, setActiveTab] = useState<TabOption>('upcoming');

  const handleTabChange = (tab: TabOption) => {
    setActiveTab(tab);
  };

  return (
    <section className="past-draws w-full py-6">
        <h2 className='text-2xl font-bold text-white text-left mb-8'>
            My Draws
        </h2>
    <div className="w-full mx-auto mt-8">
      <div className="flex p-2 rounded-full bg-white bg-opacity-10">
        <button
          className={`flex-1 py-2 text-sm text-center rounded-full ${
            activeTab === 'upcoming' ? 'text-white bg-white bg-opacity-10' : 'text-lottoblue-600'
          }`}
          onClick={() => handleTabChange('upcoming')}
        >
          Upcoming
        </button>
        <button
          className={`flex-1 py-2 text-sm text-center rounded-full  ${
            activeTab === 'results' ? 'text-white bg-white bg-opacity-10' : 'text-lottoblue-600'
          }`}
          onClick={() => handleTabChange('results')}
        >
          Results
        </button>
      </div>

      <div className="p-4">
        {activeTab === 'upcoming' && <UserEntries currentDraws={currentEntries} drawId={drawId} endDate={endDate} />}
        {activeTab === 'results' && <UserWins />}
      </div>
    </div>
    </section>
  );
};

export default UserResults;
