import {
  GalaAnalytics,
  GalEnvironment,
  GalReportingGroups,
  GalEvents,
} from '@gala-analytics/core'
import { globalEvents } from './events'

const galEnvironmentForEnv: Record<string, GalEnvironment> = {
  dev: GalEnvironment.dev,
  stage: GalEnvironment.staging,
  prod: GalEnvironment.production,
}

const analyticsEnvironment =
  galEnvironmentForEnv[process.env.REACT_APP_GALA_ANALYTICS_ENV ?? '']

export const analytics =
  analyticsEnvironment &&
  new GalaAnalytics({
    publisherGroup: GalReportingGroups.product,
    publisherId:
      'Vew6oLuUNZl0OiI9msG2dvi5gYr9cuBmw6sWa02wxhIKc65tEpvAPR2H5dihxAnN',
    reportingUri: 'https://alpha-data.gala.com',
    environment: analyticsEnvironment,
  })

export async function trySendAnalytics<
  TEventType extends Parameters<GalaAnalytics['report']>[0],
>(report: TEventType) {
  if (!analytics) {
    console.warn('Analytics disabled - no environment specified')
    console.log('Analytics event', report)
    return
  }

  try {
    await analytics.report(report)
  } catch (err) {
    console.error(err)
  }
}

globalEvents.on('gameEvent', async (eventName, data) => {
  await trySendAnalytics({
    type: GalEvents.GameEventDTO,
    subeventName: eventName,
    extra: data,
  })
})

globalEvents.on('buttonPress', async (buttonName, extra) => {
  await trySendAnalytics({
    type: GalEvents.PressDTO,
    id: buttonName,
    extra,
  })
})

globalEvents.on('authedAsUser', async (userId) => {
  if (userId && analytics) {
    analytics.setUserId(userId)

    await trySendAnalytics({
      type: GalEvents.LoginDTO,
      galaUserId: userId,
      extra: {
        utmParams: localStorage.getItem('utmParams'),
      }
    })
  }
})

globalEvents.on('navigate', async (to, from) => {
  await trySendAnalytics({
    type: GalEvents.NavigateDTO,
    from,
    to,
    extra: {
      url: window.location.href,
      utmParams: localStorage.getItem('utmParams'),
    }
  })
})

globalEvents.on('loggedOut', async () => {
  await trySendAnalytics({
    type: GalEvents.LogoutDTO,
  })
})
