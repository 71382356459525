import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type ReactNode,
  type SetStateAction,
} from 'react'
import { BackendRequester } from 'src/backend_requests'
import { galaTokenId } from 'src/constants'
import { UserType, type IUserProfile, type UserInventory } from 'src/types'
import { globalEvents } from './events'

interface AuthContextType {
  backendRequester: BackendRequester
  isLoginSplashScreenVisible: boolean
  isPendingAuth: boolean
  isPendingInventory: boolean
  isPendingProfile: boolean
  getInventory: () => void
  logIn: () => void
  logOut: () => void
  setIsLoginSplashScreenVisible: Dispatch<SetStateAction<boolean>>
  useableGala: number
  userInventory: UserInventory
  userProfile: IUserProfile | undefined
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const isMounted = useRef(false)
  const [isPendingAuth, setIsPendingAuth] = useState(false)
  const [authToken, setAuthToken] = useState<string | null>(null)
  const [isLoginSplashScreenVisible, setIsLoginSplashScreenVisible] =
    useState(false)
  const [isPendingProfile, setIsPendingProfile] = useState(false)
  const [isPendingInventory, setIsPendingInventory] = useState(false)
  const [userInventory, setUserInventory] = useState<UserInventory>([])
  const [userProfile, setUserProfile] = useState<IUserProfile | undefined>(
    undefined
  )

  const backendRequester = useMemo(
    () => new BackendRequester(authToken),
    [authToken]
  )

  const useableGala = useMemo(
    () =>
      userInventory.find((item) => item.itemId === galaTokenId)
        ?.useableQuantity ?? 0,
    [userInventory]
  )

  const logIn = () => {
    window.location.href = `${process.env.REACT_APP_SSO_URI}/game-burn-allowance?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      process.env.REACT_APP_REDIRECT_URI ?? ''
    )}&includeToken`
  }

  const logOut = useCallback(async () => {
    await backendRequester.logout()
    globalEvents.emit('loggedOut')

    window.location.reload()
  }, [backendRequester])

  const getInventory = useCallback(async () => {
    setIsPendingInventory(true)
    if (backendRequester) {
      const inventory = await backendRequester.getInventory()
      setUserInventory(inventory.inventory)
    }
    setIsPendingInventory(false)
  }, [backendRequester])

  useEffect(() => {
    async function doInitialAuth() {
      // Workaround for React calling the effect twice in dev mode.
      // That causes a race condition since the server will issue
      // two separate cookies but only the second will be valid,
      // but the browser might end up using the first.
      if (isMounted.current) {
        return
      }

      isMounted.current = true

      globalEvents.emit('navigate', 'main')

      setIsPendingAuth(true)

      if (window.location.search) {
        const params = new URLSearchParams(window.location.search)

        const utmParams = Array.from(params).filter(([key]) =>
          key.startsWith('utm_')
        )
        const utmParamString = utmParams
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
        if (utmParamString) {
          localStorage.setItem('utmParams', utmParamString)
        }

        const token = params.get('token')
        const success = params.get('success')

        if (success === 'true') {
          setAuthToken(token)
          const authedBackendRequester = new BackendRequester(token)
          await authedBackendRequester.login()
        }

        if (success || token) {
          params.delete('token')
          params.delete('success')
          window.history.replaceState({}, '', `?${params.toString()}`)
        }
      } else {
        const hasSession = await backendRequester.hasSession()
        if (!hasSession) {
          await backendRequester.login()
        }
      }

      setIsPendingAuth(false)

      setIsPendingProfile(true)

      const profile = await backendRequester.getUserProfile()
      if (profile?.userProfile) {
        setUserProfile({
          ...profile.userProfile,
          type: profile.type,
        })
        if (profile.type !== UserType.AnonymousUser) {
          globalEvents.emit('authedAsUser', profile.userId)
        }
      }

      setIsPendingProfile(false)
    }

    doInitialAuth()
  }, [backendRequester])

  useEffect(() => {
    if (userProfile) {
      getInventory()
    }
  }, [userProfile, getInventory])

  return (
    <AuthContext.Provider
      value={{
        backendRequester,
        getInventory,
        isLoginSplashScreenVisible,
        isPendingAuth,
        isPendingInventory,
        isPendingProfile,
        logIn,
        logOut,
        setIsLoginSplashScreenVisible,
        useableGala,
        userInventory,
        userProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
