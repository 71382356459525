import { type FC, type ReactNode } from 'react'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  type DialogProps,
} from '@headlessui/react'

interface FullScreenDialogProps extends DialogProps {
  children: ReactNode
  dialogTitle?: ReactNode
}

const FullScreenDialog: FC<FullScreenDialogProps> = ({
  children,
  dialogTitle,
  ...props
}) => (
  <Dialog {...props} className="relative z-20">
    <div className="fixed flex-grow flex flex-col items-center inset-0 z-20 w-screen overflow-y-auto">
      <DialogPanel
        transition
        className="relative flex-grow flex flex-col items-center justify-center w-full transform bg-deepblue-950 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {dialogTitle && <DialogTitle as="h3">{dialogTitle}</DialogTitle>}
        {children}
      </DialogPanel>
    </div>
  </Dialog>
)

export default FullScreenDialog
