import { createContext, useContext, type FC, type ReactNode } from 'react'

interface GameContextType {}

const GameContext = createContext<GameContextType | undefined>(undefined)

export const GameProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <GameContext.Provider value={{}}>{children}</GameContext.Provider>
}

export const useGame = (): GameContextType => {
  const context = useContext(GameContext)
  if (!context) {
    throw new Error('useGame must be used within an GameProvider')
  }
  return context
}
