import { type FC } from 'react'
import { MdClose } from 'react-icons/md'
import saleItem1Image from 'src/assets/images/sale-item-1.png'
import saleItem2Image from 'src/assets/images/sale-item-2.png'
import saleItem3Image from 'src/assets/images/sale-item-3.png'
import GalaTokenSideView from 'src/assets/images/gala-token-sideview.png'
import TicketEmpty from 'src/assets/images/ticket-empty.png'

import ResponsiveDialog from 'src/ResponsiveDialog'

interface GetTicketsModalProps {
  open: boolean
  onClose: () => void
}

const GetTicketsModal: FC<GetTicketsModalProps> = (props) => {
  return (
    <ResponsiveDialog {...props}>
      <div className="w-full flex justify-end">
        <button
          onClick={props.onClose}
          className="-mt-1 inline-flex gap-2 items-center justify-center p-4 bg-white bg-opacity-10 hover:bg-opacity-20 transition-all rounded-full"
        >
          <MdClose className="w-4 h-4" aria-label="close" aria-hidden="true" />
          <span className="sr-only">Back</span>
        </button>
      </div>

      <div className="p-4 text-white -mt-10">
        {/* Title Section */}
        <h1 className="text-2xl font-bold mb-8">
          How to <span className="text-lottogreen">Get Tickets</span>
        </h1>

        <div className="flex items-start mb-8 gap-6">
          <div className="flex flex-col items-center w-1/2">
            {/* Background and Image */}
            <div className="bg-white bg-opacity-5 p-4 rounded flex items-center justify-center w-full">
              <img
                src={GalaTokenSideView}
                alt="GALA Icon"
                className="w-20 h-20"
              />
            </div>
            {/* Text */}
            <p className="text-xs mt-2 self-start text-lottoblue-400 flex items-center justify-between gap-1">
              <span
                aria-hidden="true"
                className="pointer-events-none mr-1 inline-block w-[2px] h-[12px] bg-blueishpurple-700 rounded-full"
              ></span>
              Every <b className="text-white">100 $GALA</b> burn
            </p>
          </div>
          <div className="flex flex-col items-center w-1/2">
            {/* Background and Image */}
            <div className="bg-white bg-opacity-5 p-4 rounded flex items-center justify-center w-full">
              <img src={TicketEmpty} alt="Ticket Icon" className="w-20 h-20" />
            </div>
            {/* Text */}
            <p className="text-xs mt-2 self-start text-lottoblue-400 flex items-center justify-between gap-1">
              <span
                aria-hidden="true"
                className="pointer-events-none mr-1 inline-block w-[2px] h-[12px] bg-lottogreen rounded-full"
              ></span>
              You get a{' '}
              <span className="text-lottogreen font-bold">Ticket</span>
            </p>
          </div>
        </div>

        {/* Need More GALA Section */}
        <div className="mb-1">
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-lg font-semibold mb-2">Need more $GALA?</h2>
            <a
              className="text-white text-sm py-2 px-6 rounded-full mb-3 bg-white bg-opacity-5"
              href="https://connect.gala.com/?viewProduct=66a8ed9e4d218b9606182ea7"
              target="_blank"
              rel="noreferrer"
            >
              Get $GALA
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center">
          <a
            className="transition-colors text-white px-6 py-3 rounded-lg font-bold w-full text-center shadow-md bg-white bg-opacity-5"
            href="https://connect.gala.com/?viewProduct=66a8ed9e4d218b9606182ea7"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-gradient-green-gold-horizontal">
              {/* How do gradient? */}
              Buy Starter Pack to get $GALA ⚡
            </span>
            <p className="text-lottoblue-400 font-normal text-xs mt-1">
              Credit cards available
            </p>
          </a>
        </div>

        {/* Items Section */}
        {/* Items Section */}
        <div className="my-6 relative">
          <h2 className="text-lg font-semibold mb-12">
            Items which burn $GALA
          </h2>
          <div className="flex justify-center relative">
            {/* Left Card */}
            <div
              className="animate-hover animate-hover-1 w-40 pb-2 bg-gradient-to-b from-[#211d6750] via-[#211d6750] to-[#0D0D30] border-t border-lottoblue-600 backdrop-blur-sm rounded-lg shadow-2xl flex flex-col items-center justify-between absolute"
              style={{
                transform: 'rotate(-10deg) translateX(-110px)',
                animationPlayState: props.open ? 'running' : 'paused',
                zIndex: 1,
              }}
            >
              <div className="p-1 transform rotate-[10deg]">
                <img src={saleItem1Image} alt="Item 1" className="rounded-lg" />
              </div>
              <p className="text-center text-super-tiny flex flex-col justify-center">
                <span className="text-lottoblue-400">Common Ground World</span>
                Common Ground Helper Pack
              </p>
            </div>
            {/* Center Card */}
            <div
              className="animate-hover animate-hover-2 w-40 pb-2 bg-gradient-to-b from-[#211d6750] via-[#211d6750] to-[#0D0D30] border-t border-lottoblue-600 backdrop-blur-sm rounded-lg flex flex-col items-center justify-between relative"
              style={{
                zIndex: 3,
                animationPlayState: props.open ? 'running' : 'paused',
                transform: 'translateY(-20px)',
              }}
            >
              <div className="p-1">
                <img src={saleItem2Image} alt="Item 2" className="rounded-lg" />
              </div>
              <p className="text-center text-super-tiny absolute bottom-2 px-1 flex flex-col justify-center">
                <span className="text-lottoblue-400">VEXI</span>
                The Trick or Treat VEXI Box
              </p>
            </div>
            {/* Right Card */}
            <div
              className="animate-hover animate-hover-3 w-40 pb-2 rounded-lg shadow-2xl flex flex-col items-center justify-between absolute bg-gradient-to-b from-[#211d6750] via-[#211d6750] to-[#0D0D30] border-t border-lottoblue-600 backdrop-blur-sm "
              style={{
                transform: 'rotate(20deg) translateX(110px)',
                animationPlayState: props.open ? 'running' : 'paused',
                zIndex: 2,
              }}
            >
              <div className="p-1 transform -rotate-[10deg]">
                <img src={saleItem3Image} alt="Item 3" className="rounded-lg" />
              </div>
              <p className="text-center text-super-tiny flex flex-col justify-center">
                <span className="text-lottoblue-400">Common Ground World</span>
                Birdhouses Blind Collectors Sale
              </p>
            </div>
          </div>
        </div>

        {/* Explore More Button */}
        <div className="flex justify-around">
          <a
            className="text-white text-sm py-2 px-6 rounded-full mb-3 bg-white bg-opacity-10"
            href="https://games.gala.com/store?games=mirandus,common-ground-world,vox&selectedCurrency=galagyri&availability=available"
            target="_blank"
            rel="noreferrer"
          >
            Explore more NFTs
          </a>
        </div>
      </div>
    </ResponsiveDialog>
  )
}

export default GetTicketsModal
