import { type FC, useId } from 'react'

const NormalPlayTopSvg: FC<{ className?: string }> = ({ className }) => {
  const gradientId = useId()
  return (
    <svg
      viewBox="0 0 67 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
      className={className}
    >
      <path
        d="M64.7413 34.0847C64.7413 16.9528 50.8533 3.06476 33.7214 3.06476C16.5896 3.06476 2.70154 16.9528 2.70154 34.0847C2.70154 51.2165 16.5896 65.1046 33.7214 65.1046C50.8533 65.1046 64.7413 51.2165 64.7413 34.0847Z"
        fill="#121212"
        stroke={`url(#${gradientId})`}
        strokeWidth="4.32836"
      />
      <path
        d="M27.0664 35.3309L30.1323 37.0828V47.0106L21.5186 42.0467V28.761L18.5986 27.0091V43.6527L33.0523 51.9744V35.4769L27.0664 31.973V35.3309Z"
        fill="white"
      />
      <path
        d="M34.9502 35.4768V42.3386L37.8701 40.5866V37.0827L46.4839 32.1188V42.0466L34.9502 48.7624V52.1203L49.4038 43.7985V27.155L34.9502 35.4768Z"
        fill="white"
      />
      <path
        d="M36.9937 28.615L33.9277 30.367L25.314 25.4031L33.9277 20.4392L45.4614 27.155L48.3814 25.4031L33.9277 17.0813L19.4741 25.4031L33.9277 33.7249L39.9136 30.221L36.9937 28.615Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="4.86572"
          y1="5.22894"
          x2="62.5772"
          y2="62.9404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCF6FF" />
          <stop offset="0.485" stopColor="#557399" />
          <stop offset="0.78" stopColor="#557399" />
          <stop offset="1" stopColor="#97C5FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default NormalPlayTopSvg
