export interface Events {
  authedAsUser: (userId?: string) => Promise<void>
  loggedOut: () => Promise<void>
  gameEvent: (
    eventName: string,
    data?: Record<string, unknown>
  ) => Promise<void>
  buttonPress: (buttonName: string, extra?: any) => Promise<void>
  navigate: (to: string, from?: string, extra?: any) => Promise<void>
}

export interface IEventEmitter {
  emit<TEventName extends keyof Events>(
    event: TEventName,
    ...args: Parameters<Events[TEventName]>
  ): Promise<void>

  on<TEventName extends keyof Events>(
    event: TEventName,
    listener: (...args: Parameters<Events[TEventName]>) => Promise<void>
  ): void
}

// Typed event emitter who awaits listeners
export class JSEventEmitter implements IEventEmitter {
  private listeners: Partial<
    Record<keyof Events, Array<(...args: any[]) => Promise<void>>>
  > = {}

  async emit<TEventName extends keyof Events>(
    event: TEventName,
    ...args: Parameters<Events[TEventName]>
  ): Promise<void> {
    const listeners = this.listeners[event]
    if (!listeners) {
      return
    }

    const results = await Promise.allSettled(
      listeners.map((listener) => listener(...args))
    )

    for (const result of results) {
      if (result.status === 'rejected') {
        console.error(result.reason)
      }
    }
  }

  on<TEventName extends keyof Events>(
    event: TEventName,
    listener: (...args: Parameters<Events[TEventName]>) => Promise<void>
  ): void {
    const existingListeners: Array<Events[TEventName]> =
      this.listeners[event] ?? []
    existingListeners.push(listener as any)
    this.listeners[event] = existingListeners
  }
}

export const globalEvents = new JSEventEmitter()
