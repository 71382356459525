import { type FC, type HTMLAttributes } from 'react'
import { HiArrowCircleLeft } from 'react-icons/hi'
import { footerLinks } from 'src/constants'
import ButtonPrimary from 'src/ButtonPrimary'
import SplashGradientTop from './SplashGradientTop'
import SplashGradientBottom from './SplashGradientBottom'
import SplashGraphic from './SplashGraphic'
import SplashTitle from './SplashTitle'

interface SplashScreenProps extends HTMLAttributes<HTMLDivElement> {
  backCtaText?: string
  ctaText: string
  onBack?: () => void
  onStart: () => void
}

const SplashScreen: FC<SplashScreenProps> = ({
  backCtaText,
  className,
  ctaText,
  onStart,
  onBack,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`flex flex-col items-center justify-center flex-grow overflow-hidden ${className ?? ''}`}
    >
      <div className="splash-screen px-6 pt-14 pb-10 relative flex flex-shrink justify-center items-center text-white text-center">
        <div className="absolute top-0 left-0 -translate-x-1/3 -translate-y-1/2">
          <SplashGradientTop />
        </div>
        <div className="absolute bottom-0 right-0 translate-x-1/3 translate-y-1/2">
          <SplashGradientBottom />
        </div>
        {onBack && (
          <div className="absolute top-6 left-6">
            <button
              className="text-white"
              aria-label={backCtaText || 'Back'}
              onClick={onBack}
            >
              <HiArrowCircleLeft className="w-6 h-6" />
            </button>
          </div>
        )}
        <div className="splash-content relative flex flex-col items-center w-full max-w-sm">
          <SplashTitle />
          <SplashGraphic className="mt-10 mb-9" />
          <div className="text-neutral-450 font-medium mb-4">
            <p>Ready to play for real rewards?</p>
            <p>Get started for free now.</p>
          </div>
          <ButtonPrimary onClick={onStart} className="w-full max-w-[20rem]">
            {ctaText}
          </ButtonPrimary>
          <div className="mt-7">
            <p className="text-neutral-450 text-xs">
              By continuing, you agree to our{' '}
              <a
                href={footerLinks.terms}
                target="_blank"
                rel="noreferrer"
                className="underline hover:no-underline"
              >
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                href={footerLinks.privacyPolicy}
                target="_blank"
                rel="noreferrer"
                className="underline hover:no-underline"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
