import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAuth } from '../AuthContext'
import LottoMarqueeCountdown from './LottoMarqueeCountdown'

const LottoWidget: React.FC<{ refreshInterval?: number }> = ({
  refreshInterval = 10000,
}) => {
  const endDate = useRef<Date>()
  const countdownInterval = useRef<ReturnType<typeof setInterval>>()
  const potSize = useRef<number | undefined>(undefined)
  const [hasDrawing, setHasDrawing] = useState(true)
  const [millisecondsUntilEnd, setMillisecondsUntilEnd] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })
  const { backendRequester } = useAuth()
  const ctaLink = window.location.origin

  useEffect(() => {
    if (!millisecondsUntilEnd) {
      return
    }

    const remaining = {
      days: Math.floor(millisecondsUntilEnd / (1000 * 60 * 60 * 24)),
      hours: Math.floor((millisecondsUntilEnd / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((millisecondsUntilEnd / (1000 * 60)) % 60),
    }

    setTimeRemaining({
      days: remaining.days,
      hours: remaining.hours,
      minutes: remaining.minutes,
    })
  }, [millisecondsUntilEnd])

  const ctaText = useMemo(
    () => (hasDrawing ? 'play now' : 'coming soon'),
    [hasDrawing]
  )

  const getDate = useCallback(async () => {
    try {
      const response = await backendRequester.getCurrentDrawing()
      endDate.current = new Date(response.endDate)
      potSize.current = response.potSize
      setMillisecondsUntilEnd(
        new Date(response.endDate).getTime() - new Date().getTime()
      )
      setHasDrawing(true)
    } catch (e) {
      setHasDrawing(false)
      console.error(e)
    }
  }, [backendRequester])

  useEffect(() => {
    getDate()
    countdownInterval.current = setInterval(() => {
      getDate()
    }, refreshInterval)

    return () => clearInterval(countdownInterval.current)
  }, [getDate, refreshInterval])

  return (
    <div className="lotto-widget font-figtree h-full w-full overflow-hidden flex items-center justify-center">
      <div className="py-8 px-4 sm:px-6 text-center relative text-white">
        <div className={potSize.current === undefined ? 'invisible' : ''}>
          <LottoMarqueeCountdown
            potSize={potSize.current ?? 0}
            timeRemaining={timeRemaining}
          />
        </div>
        {hasDrawing ? (
          <a href={ctaLink} target="_blank" rel="noopener noreferrer">
            <span className="block w-full mx-auto px-20 py-3.5 shadow-lg text-lg font-black lottoCTA-blue whitespace-nowrap uppercase">
              {ctaText}
            </span>
          </a>
        ) : (
          <span className="block w-full mx-auto px-20 py-3.5 shadow-lg text-lg font-black lottoCTA-blue whitespace-nowrap uppercase">
            {ctaText}
          </span>
        )}
      </div>
    </div>
  )
}

export default LottoWidget
