import { ICurrentDrawing, IDrawing, IDrawingEntry, IAllDrawingEntry, UserType } from 'src/types'
import { galaTokenId } from './constants'

export class BackendRequester {
  constructor(private readonly userJwt?: string | null) {}

  async doRequest(path: string, method: string, body: any) {
    if (typeof process.env.REACT_APP_BACKEND_BASE_URI !== 'string') {
      throw new Error('REACT_APP_BACKEND_BASE_URI not set')
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URI}${path}`,
      {
        method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...(this.userJwt && {
            Authorization: `GalaPlatform ${this.userJwt}`,
          }),
        },
        body: JSON.stringify(body),
      }
    )

    return response
  }

  async doRequestAndParse<TResponseType>(
    path: string,
    method: string,
    body: any,
    isError = (res: Response) => !res.ok
  ): Promise<TResponseType> {
    const response = await this.doRequest(path, method, body)

    if (isError(response)) {
      console.error(await response.text())
      throw new Error(`Request failed with status ${response.status}`)
    }

    return response.json()
  }

  async hasSession() {
    const response = await this.doRequest(
      '/v1/sessions/current',
      'GET',
      undefined
    )
    if (response.status === 404) {
      return false
    }

    if (response.ok) {
      return true
    }

    throw new Error(`Failed to check session: ${response.status}`)
  }

  login() {
    return this.doRequestAndParse('/v1/sessions', 'POST', {})
  }

  logout() {
    return this.doRequestAndParse('/v1/sessions/current', 'DELETE', {})
  }

  async getInventory() {
    const response = await this.doRequest(
      '/v1/users/me/inventory',
      'GET',
      undefined
    )

    if (response.ok) {
      return response.json() as Promise<{
        inventory: Array<{
          itemId: string
          totalQuantity: number
          useableQuantity: number
        }>
      }>
    }

    return { inventory: [] }
  }

  async getUserProfile() {
    const response = await this.doRequest(
      '/v1/users/me/profile',
      'GET',
      undefined
    )

    if (response.ok) {
      return response.json() as Promise<{
        userId: string
        type: UserType
        userProfile: { avatar: string; displayName: string }
      }>
    }

    return undefined
  }

  async getCurrentDrawing() {
    const response = await this.doRequestAndParse<{
      id: number
      burnAmount: number
      entryCount: number
      potSize: number
      endDate: string
    }>('/v1/bl/drawings/current', 'GET', undefined)

    return {
      id: response.id,
      burnAmount: response.burnAmount,
      entryCount: response.entryCount,
      potSize: response.potSize,
      endDate: new Date(response.endDate),
    } satisfies ICurrentDrawing
  }

  getCurrentDrawingEntries() {
    return this.doRequestAndParse<{
      totalEntriesPermitted: number
      totalEntriesRemaining: number
      entries: IDrawingEntry[]
    }>('/v1/bl/drawings/current/entries', 'GET', undefined)
  }

  async getPastDrawings() {
    const allDrawings = await this.doRequestAndParse<{
      drawings: IDrawing[]
    }>('/v1/bl/drawings', 'GET', undefined)

    return allDrawings.drawings.filter((d) => d.status === 'completed')
  }

  async getMostRecentEntries(options?: { offset?: number; limit?: number }) {
    const offset = options?.offset ?? 0
    const limit = options?.limit ?? 100;

    const mostRecentEntries = await this.doRequestAndParse<{
      entries: IAllDrawingEntry[],
    }>(`/v1/bl/entries?offset=${offset}&limit=${limit}`, 'GET', undefined)

    return mostRecentEntries.entries
  }

  submitTickets(tickets: number[][]) {
    return this.doRequestAndParse<{}>(
      '/v1/bl/drawings/current/entries',
      'POST',
      {
        entries: tickets.map((t) => ({
          numbers: t,
        })),
      }
    )
  }

  burnGala(amount: number) {
    const galaEncoded = encodeURIComponent(galaTokenId)
    return this.doRequestAndParse<{}>(
      `/v1/users/me/inventory/${galaEncoded}`,
      'PATCH',
      {
        operation: 'burn',
        amount,
      }
    )
  }
}
