import { type FC } from 'react'
import { DialogTitle } from '@headlessui/react'
import { useAuth } from 'src/AuthContext'
import FullScreenDialog from 'src/FullScreenDialog'
import SplashScreen from 'src/SplashScreen/SplashScreen'

const LoginSplashScreen: FC = () => {
  const { isLoginSplashScreenVisible, logIn, setIsLoginSplashScreenVisible } =
    useAuth()

  const handleLoginSplashDismiss = () => {
    setIsLoginSplashScreenVisible(false)
  }
  return (
    <FullScreenDialog
      open={isLoginSplashScreenVisible}
      onClose={handleLoginSplashDismiss}
    >
      <DialogTitle as="h3" className="sr-only">
        Login
      </DialogTitle>
      <SplashScreen
        className="w-full"
        backCtaText="Back to game"
        ctaText="Login with Gala"
        onStart={logIn}
        onBack={handleLoginSplashDismiss}
      />
    </FullScreenDialog>
  )
}

export default LoginSplashScreen
