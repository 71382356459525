import { type FC, useId } from 'react'

const PowerPlayBodySvg: FC<{ className?: string }> = ({ className }) => {
  const gradientId = useId()
  const filterId1 = useId()
  const filterId2 = useId()
  const filterId3 = useId()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511 185"
      fill="none"
      aria-hidden="true"
      role="img"
      className={`rounded-[10%] ${className}`}
    >
      <g filter={`url(#${filterId1})`}>
        <rect
          x="3.01001"
          y="3"
          width="504.98"
          height="178.397"
          rx="23.019"
          fill="#D9FF00"
        />
        <g filter={`url(#${filterId2})`}>
          <rect
            x="11.6421"
            y="11.6321"
            width="487.716"
            height="161.133"
            rx="17.2643"
            fill="#0E0737"
            fillOpacity="0.8"
            shapeRendering="crispEdges"
          />
          <rect
            x="11.6421"
            y="11.6321"
            width="487.716"
            height="161.133"
            rx="17.2643"
            fill={`url(#${gradientId})`}
            fillOpacity="0.2"
            shapeRendering="crispEdges"
          />
          <rect
            x="12.3615"
            y="12.3515"
            width="486.277"
            height="159.695"
            rx="16.5449"
            stroke="white"
            strokeOpacity="0.05"
            strokeWidth="1.43869"
            shapeRendering="crispEdges"
          />
          <g filter={`url(#${filterId3})`}>
            <rect
              x="34.6612"
              y="34.6512"
              width="441.678"
              height="115.095"
              rx="11.5095"
              fill="white"
              fillOpacity="0.2"
            />
            <rect
              x="35.3805"
              y="35.3705"
              width="440.239"
              height="113.656"
              rx="10.7902"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="1.43869"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id={filterId1}
          x="3.01001"
          y="3"
          width="504.98"
          height="179.836"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.43869" />
          <feGaussianBlur stdDeviation="1.43869" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_0_13"
          />
        </filter>
        <filter
          id={filterId2}
          x="0.13262"
          y="0.122602"
          width="510.735"
          height="184.152"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.75476" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0696181 0 0 0 0 0.556945 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_13"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_13"
            result="shape"
          />
        </filter>
        <filter
          id={filterId3}
          x="34.6612"
          y="34.6512"
          width="441.678"
          height="116.534"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.43869" />
          <feGaussianBlur stdDeviation="1.43869" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_0_13"
          />
        </filter>
        <linearGradient
          id={gradientId}
          x1="12.5521"
          y1="11.6321"
          x2="460.32"
          y2="248.334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008CFF" />
          <stop offset="0.595" stopColor="#DBFF00" />
          <stop offset="1" stopColor="#B300FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PowerPlayBodySvg
