import { type CSSProperties, type FC, useId } from 'react'
import styles from './lottoMarquee.module.css'

const LottoMarqueeLights: FC<{ className?: string }> = ({ className }) => {
  const lightOffId = useId()
  const lightOnId = useId()
  const style: CSSProperties = {
    // @ts-expect-error - css vars are valid but not included in the type def
    '--light-off-url': `url(#${lightOffId})`,
    '--light-on-url': `url(#${lightOnId})`,
  }
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 307 108"
      xmlSpace="preserve"
      role="img"
      aria-hidden="true"
      className={className}
      style={style}
    >
      <g>
        <linearGradient id={lightOffId}>
          <stop offset="0" style={{ stopColor: '#98BFFF' }} />
          <stop offset="0.4867" style={{ stopColor: '#4375C5' }} />
          <stop offset="1" style={{ stopColor: '#98BFFF' }} />
        </linearGradient>
        <linearGradient id={lightOnId}>
          <stop offset="0" style={{ stopColor: '#F0FF98' }} />
          <stop offset="0.4867" style={{ stopColor: '#A2B534' }} />
          <stop offset="1" style={{ stopColor: '#F0FF98' }} />
        </linearGradient>
        <g className="bubble-24">
          <circle className={styles.bubbleEven} cx="16.9" cy="90.5" r="3.4" />
        </g>
        <g className="bubble-22">
          <circle className={styles.bubbleEven} cx="71.1" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-20">
          <circle className={styles.bubbleEven} cx="126.2" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-26">
          <circle className={styles.bubbleEven} cx="14.8" cy="38.8" r="3.4" />
        </g>
        <g className="bubble-4">
          <circle className={styles.bubbleEven} cx="98.7" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-2">
          <circle className={styles.bubbleEven} cx="43.6" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-23">
          <circle className={styles.bubbleOdd} cx="43.6" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-21">
          <circle className={styles.bubbleOdd} cx="98.7" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-1">
          <circle className={styles.bubbleOdd} cx="17.4" cy="14.7" r="3.4" />
        </g>
        <g className="bubble-5">
          <circle className={styles.bubbleOdd} cx="126.2" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-3">
          <circle className={styles.bubbleOdd} cx="71.1" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-25">
          <circle className={styles.bubbleOdd} cx="14.8" cy="65.5" r="3.4" />
        </g>
        <g className="bubble-14">
          <circle className={styles.bubbleEven} cx="289.8" cy="90.5" r="3.4" />
        </g>
        <g className="bubble-16">
          <circle className={styles.bubbleEven} cx="235.5" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-18">
          <circle className={styles.bubbleEven} cx="181.7" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-12">
          <circle className={styles.bubbleEven} cx="291.9" cy="38.8" r="3.4" />
        </g>
        <g className="bubble-8">
          <circle className={styles.bubbleEven} cx="208" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-10">
          <circle className={styles.bubbleEven} cx="263.1" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-15">
          <circle className={styles.bubbleOdd} cx="263.1" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-17">
          <circle className={styles.bubbleOdd} cx="208" cy="93.9" r="3.4" />
        </g>
        <g className="bubble-11">
          <circle className={styles.bubbleOdd} cx="289.3" cy="14.7" r="3.4" />
        </g>
        <g className="bubble-7">
          <circle className={styles.bubbleOdd} cx="180.5" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-9">
          <circle className={styles.bubbleOdd} cx="235.5" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-13">
          <circle className={styles.bubbleOdd} cx="291.9" cy="65.5" r="3.4" />
        </g>
        <g className="bubble-6">
          <circle className={styles.bubbleEven} cx="153.8" cy="11.3" r="3.4" />
        </g>
        <g className="bubble-19">
          <circle className={styles.bubbleOdd} cx="153.8" cy="93.9" r="3.4" />
        </g>
      </g>
    </svg>
  )
}

export default LottoMarqueeLights
