import { FC } from "react"

export const TicketProgressBar: FC<{
  progress: number
  total: number
}> = ({progress, total}) => { 
  const fillStyle = { fill: "#1B193A" }
  const activeFillStyle = { fill: '#D9FF00' }
  return (
    <div className="relative h-20 w-20">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 86 86"> 
      <path className="transition-all" style={progress >= 8 ? activeFillStyle : fillStyle} d="M45.1,82.2c0-2.1,1.6-3.8,3.7-4.1c5.3-0.9,10.2-2.9,14.4-5.8c1.7-1.2,4-1.1,5.5,0.4c1.6,1.6,1.5,4.3-0.3,5.6
        c-5.5,4-12.1,6.7-19.1,7.7C46.9,86.3,45.1,84.5,45.1,82.2z"/>
      <path className="transition-all" style={progress >= 7 ? activeFillStyle : fillStyle} d="M82.1,45.6c2.3,0,4.1,1.9,3.7,4.1c-1.1,7.1-4,13.7-8.1,19.2c-1.3,1.8-4,1.9-5.5,0.3c-1.5-1.5-1.5-3.8-0.3-5.5
        c3-4.2,5.2-9.2,6.1-14.5C78.4,47.2,80.1,45.6,82.1,45.6z"/>
      <path className="transition-all" style={progress >= 6 ? activeFillStyle : fillStyle} d="M72.3,16.9c-1.5,1.5-1.5,3.8-0.3,5.5c3,4.3,5.2,9.2,6.1,14.6c0.4,2,2,3.6,4.1,3.6c2.3,0,4.1-1.9,3.7-4.1
        c-1.1-7.2-3.9-13.7-8-19.3C76.5,15.3,73.9,15.3,72.3,16.9z"/>
      <path className="transition-all" style={progress >= 5 ? activeFillStyle : fillStyle} d="M68.7,13.4c1.6-1.6,1.5-4.2-0.3-5.6C62.8,3.8,56.3,1,49.1,0c-2.2-0.3-4.1,1.5-4.1,3.7c0,2.1,1.6,3.8,3.7,4.1
        c5.3,0.9,10.3,2.9,14.5,5.9C64.9,14.9,67.3,14.8,68.7,13.4z"/>
      <path className="transition-all" style={progress >= 4 ? activeFillStyle : fillStyle} d="M40.1,3.9c0-2.3-1.9-4.1-4.2-3.7c-7,1.1-13.4,4-18.9,8c-1.8,1.3-1.9,4-0.3,5.5c1.5,1.5,3.8,1.5,5.5,0.3
        c4.2-3,9-5.1,14.2-6.1C38.5,7.6,40.1,5.9,40.1,3.9z"/>
      <path className="transition-all" style={progress >= 3 ? activeFillStyle : fillStyle} d="M3.8,40.6c-2.3,0-4.1-1.9-3.7-4.1c1-6.9,3.7-13.3,7.7-18.8c1.3-1.8,4-1.9,5.6-0.3c1.5,1.5,1.5,3.8,0.4,5.5
        c-2.9,4.2-4.9,9-5.8,14.1C7.5,39,5.8,40.6,3.8,40.6z"/>
      <path className="transition-all" style={progress >= 2 ? activeFillStyle : fillStyle} d="M13.4,68.7c1.5-1.5,1.5-3.8,0.3-5.5c-2.9-4.1-4.9-8.9-5.8-14.1c-0.4-2-2-3.6-4.1-3.6c-2.3,0-4.1,1.9-3.7,4.1
        C1.1,56.6,3.8,63,7.8,68.4C9.1,70.3,11.8,70.3,13.4,68.7z"/>
      <path className="transition-all" style={progress >= 1 ? activeFillStyle : fillStyle} d="M22.3,72c-1.7-1.2-4-1.1-5.5,0.3c-1.6,1.6-1.5,4.2,0.3,5.5c5.4,4,11.8,6.8,18.8,8c2.2,0.4,4.2-1.4,4.2-3.7
        c0-2.1-1.6-3.7-3.6-4.1C31.3,77,26.5,75,22.3,72z"/>
      </svg>
      <p className="text-lottoblue-400 font-bold absolute top-1/2 -translate-y-1/2 text-center w-full text-lg"><span className="text-lottogreen">{progress}</span> of {total}</p>
    </div>
  )
}

export default TicketProgressBar