import { type FC, useCallback, useEffect, useState } from 'react'
import { useAuth } from 'src/AuthContext'
import Header from 'src/Header'
import {
  ICurrentDrawing,
  IDrawing,
  IDrawingEntry,
  PageId,
  UserType,
} from 'src/types'
import { MainScreen } from './Main'
import { TicketsScreen } from './Tickets'
import { globalEvents } from 'src/events'

const GameScreen: FC = () => {
  const { backendRequester, userProfile } = useAuth()

  const [currentDrawing, setCurrentDrawing] = useState<ICurrentDrawing | null>(
    null
  )
  const [pastDrawings, setPastDrawings] = useState<IDrawing[]>([])
  const [entries, setEntries] = useState<IDrawingEntry[]>([])
  const [totalEntriesPermitted, setTotalEntriesPermitted] = useState(0)
  const [remainingEntriesPermitted, setRemainingEntriesPermitted] = useState(0)
  const [page, setPageState] = useState<PageId>('main')

  const setPage = useCallback(
    (newPage: PageId) => {
      globalEvents.emit('navigate', newPage, page)
      setPageState(newPage)
    },
    [page]
  )

  const handleChangePage = (newPage: PageId) => {
    setPage(newPage)
  }

  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  })

  const fetchEntries = useCallback(async () => {
    try {
      if (userProfile?.type === UserType.GalaPlatformGameJwt) {
        const entries = await backendRequester.getCurrentDrawingEntries()
        setEntries(entries.entries)
        setRemainingEntriesPermitted(entries.totalEntriesRemaining)
        setTotalEntriesPermitted(entries.totalEntriesPermitted)
      }
    } catch (err) {
      console.log(err)
    }
  }, [userProfile, backendRequester])

  useEffect(() => {
    if (!currentDrawing) {
      return
    }

    const now = new Date()
    const diff = currentDrawing.endDate.getTime() - now.getTime()

    if (diff <= 0) {
      if (
        timeRemaining.days !== 0 ||
        timeRemaining.hours !== 0 ||
        timeRemaining.minutes !== 0
      ) {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0 })
      }
      return
    }

    setTimeRemaining({
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
    })
  }, [
    currentDrawing,
    timeRemaining.days,
    timeRemaining.hours,
    timeRemaining.minutes,
  ])

  useEffect(() => {
    async function fetchData() {
      const [currentDrawing, pastDrawings] = await Promise.all([
        backendRequester.getCurrentDrawing(),
        backendRequester.getPastDrawings(),
        fetchEntries(),
      ])

      setCurrentDrawing(currentDrawing)
      setPastDrawings(pastDrawings)
    }

    fetchData()

    // Update pot and countdown info every minute
    const intervalId = setInterval(async () => {
      const response = await backendRequester.getCurrentDrawing()
      setCurrentDrawing(response)
    }, 60000)

    return () => clearInterval(intervalId)
  }, [backendRequester, fetchEntries])

  const submitTickets = useCallback(
    async (tickets: Array<{ numbers: number[] }>) => {
      await backendRequester.submitTickets(tickets.map((t) => t.numbers))
      await fetchEntries()
    },
    [backendRequester, fetchEntries]
  )

  return (
    <>
      <div className="absolute top-0 left-0 w-full z-[5] overflow-hidden">
        <div className="max-w-screen-2xl mx-auto">
          <Header />
        </div>
      </div>
      {page === 'main' && currentDrawing && (
        <MainScreen
          timeRemaining={timeRemaining}
          totalEntriesPermitted={remainingEntriesPermitted}
          pastDraws={pastDrawings}
          changePage={handleChangePage}
          currentDrawing={currentDrawing}
          currentPot={currentDrawing.potSize}
          currentEntries={entries}
        />
      )}
      {page === 'tickets' && currentDrawing && (
        <TicketsScreen
          changePage={handleChangePage}
          existingTickets={entries}
          remainingEntriesPermitted={remainingEntriesPermitted}
          totalEntriesPermitted={totalEntriesPermitted}
          submitTickets={submitTickets}
          currentDrawing={currentDrawing}
        />
      )}
    </>
  )
}

export default GameScreen
