import { type FC, useId } from 'react'

const SplashGradientBottom: FC = () => {
  const gradientId = useId()
  const filterId = useId()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1108"
      height="729"
      viewBox="0 0 1108 729"
      fill="none"
    >
      <g className="mix-blend-screen" filter={`url(#${filterId})`}>
        <path
          d="M119 498.232L535.314 609.784L988.677 230.551L572.363 119C561.021 497.266 119 498.232 119 498.232Z"
          fill={`url(#${gradientId})`}
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <filter
          id={filterId}
          x="0.155289"
          y="0.155228"
          width="1107.37"
          height="728.473"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="59.4224"
            result="effect1_foregroundBlur_13_234"
          />
        </filter>
        <linearGradient
          id={gradientId}
          x1="580.425"
          y1="530.423"
          x2="513.456"
          y2="266.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#341D70" />
          <stop offset="1" stopColor="#560073" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SplashGradientBottom
