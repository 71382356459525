import { type FC } from 'react'
import { useAuth } from 'src/AuthContext'
import AvatarLoading from 'src/User/AvatarLoading'

const Avatar: FC<{ className?: string }> = ({ className }) => {
  const { userProfile } = useAuth()
  return (
    <>
      {userProfile?.avatar && (
        <img
          src={userProfile.avatar}
          alt={userProfile.displayName ?? ''}
          width="40"
          height="40"
          className={`rounded-full bg-white/10 ${className ?? ''}`}
        />
      )}
      {!userProfile?.avatar && <AvatarLoading className={className} />}
    </>
  )
}

export default Avatar
