import { FC } from 'react'
import { useAuth } from 'src/AuthContext'
import {
  ICurrentDrawing,
  IDrawing,
  IDrawingEntry,
  ITimeRemaining,
  PageId,
  UserType,
} from 'src/types'
import PastDrawingsCarousel from 'src/UIComponents/PastDrawings'
import HowToPlayCards from 'src/UIComponents/HowToPlayCards'
import CurrentPools from 'src/UIComponents/CurrentPools'
import UserResults from 'src/UIComponents/UserResults'
import LottoMarqueeCountdown from 'src/UIComponents/LottoMarqueeCountdown'

export const MainScreen: FC<{
  timeRemaining: ITimeRemaining
  totalEntriesPermitted: number
  changePage: (page: PageId) => void
  pastDraws: IDrawing[]
  currentPot: number
  currentDrawing: ICurrentDrawing
  currentEntries: IDrawingEntry[]
}> = ({
  timeRemaining,
  totalEntriesPermitted,
  pastDraws,
  changePage,
  currentPot,
  currentDrawing,
  currentEntries,
}) => {
  const { userProfile, logIn } = useAuth()
  const handlePlayNowClick = () => {
    if (userProfile?.type === UserType.AnonymousUser) {
      logIn()
    } else {
      changePage('tickets')
    }
  }

  return (
    <>
      <div className="flex flex-col items-center w-full max-w-[600px] pt-14 sm:pt-0 px-6 pb-6">
        {/* Current Draw Section */}
        <LottoMarqueeCountdown
          potSize={currentPot}
          timeRemaining={timeRemaining}
        />

        <UserResults
          currentEntries={currentEntries}
          drawId={currentDrawing.id}
          endDate={currentDrawing.endDate}
        />

        {pastDraws.length > 0 && <PastDrawingsCarousel drawings={pastDraws} />}
        <CurrentPools currentPotSize={currentPot} />

        <HowToPlayCards timeRemaining={timeRemaining} />
      </div>

      <div
        style={{ transform: 'translateZ(0)' }}
        className="flex flex-col justify-center gap-3 py-6 px-6 sticky bottom-0 left-0 z-20 w-full bg-lottoblue-850 bg-opacity-75 backdrop-blur-sm"
      >
        <button
          onClick={handlePlayNowClick}
          className="w-full uppercase sm:max-w-96 mx-auto px-20 py-3.5 shadow-lg text-lg font-black lottoCTA-blue whitespace-nowrap"
        >
          Play now
        </button>
        <p className="text-lottoblue-400 text-sm text-center">
          {userProfile?.type !== UserType.AnonymousUser && (
            <span>
              You have{' '}
              <span className="text-lottogreen font-bold">
                {totalEntriesPermitted} tickets
              </span>{' '}
              to enter
            </span>
          )}
          {userProfile?.type === UserType.AnonymousUser && (
            <span>Login to see your tickets</span>
          )}
        </p>
      </div>
    </>
  )
}
