import { type FC, type ReactNode, useMemo } from 'react'
import { HiXMark, HiPlus, HiArrowRightOnRectangle } from 'react-icons/hi2'
import { DialogTitle } from '@headlessui/react'
import { galaTokenId, getGalaLink } from 'src/constants'
import { useAuth } from 'src/AuthContext'
import ResponsiveDialog from 'src/ResponsiveDialog'
import Avatar from 'src/User/Avatar'
import GalaIcon from 'src/GalaIcon'

interface ProfileModalProps {
  open: boolean
  onClose: () => void
}

interface IBalanceItem {
  icon: ReactNode
  id: string
  name: string
  quantity: number
}

const ProfileModal: FC<ProfileModalProps> = (props) => {
  const { userProfile, userInventory, logOut } = useAuth()
  const balances = useMemo(() => {
    const b: IBalanceItem[] = []
    userInventory.forEach((item) => {
      switch (item.itemId) {
        case galaTokenId:
          b.push({
            icon: <GalaIcon className="w-8 h-8" />,
            id: item.itemId,
            name: 'GALA [GC]',
            quantity: item.useableQuantity,
          })
          break
      }
    })
    return b
  }, [userInventory])
  return (
    <ResponsiveDialog {...props}>
      <div className="-mt-1">
        <button
          onClick={props.onClose}
          className="flex items-center justify-center rounded-full bg-white/10 hover:bg-white/20 transition p-4 ml-auto mr-0"
        >
          <HiXMark className="w-4 h-4" aria-hidden="true" role="img" />
          <span className="sr-only">Close profile</span>
        </button>
      </div>
      <DialogTitle as="h3" className="sr-only">
        User profile
      </DialogTitle>
      <div className="flex items-center gap-4 mt-2 mb-8">
        <div>
          <Avatar className="w-14 h-14" />
        </div>
        <div>
          <p className="text-xl font-semibold">
            {userProfile?.displayName ?? 'Player'}
          </p>
        </div>
      </div>
      <p className="text-sm text-neutral-450 font-medium mb-3">Balances</p>
      <div className="rounded-2xl bg-lottoblue-900 p-2 space-y-4">
        {balances.map((item) => (
          <BalanceItem key={item.id} {...item} />
        ))}
      </div>
      <div className="mt-8 mb-4 text-sm text-lottoblue-400 font-medium space-y-4">
        <a
          href={getGalaLink}
          target="_blank"
          rel="noreferrer"
          className="hover:text-white transition flex items-center gap-2"
        >
          <HiPlus className="w-4 h-auto" aria-hidden="true" role="img" />
          <span>Get GALA</span>
        </a>
        <button
          onClick={logOut}
          className="hover:text-white transition flex items-center gap-2"
        >
          <HiArrowRightOnRectangle
            className="w-4 h-auto"
            aria-hidden="true"
            role="img"
          />
          <span>Log out</span>
        </button>
      </div>
    </ResponsiveDialog>
  )
}

const BalanceItem: FC<IBalanceItem> = ({ icon, id, name, quantity }) => (
  <div className="flex items-center gap-3">
    <div>{icon}</div>
    <div>
      <p className="text-sm font-medium">{name}</p>
    </div>
    <div className="ml-auto mr-0 pr-1">
      <p className="text-sm font-medium">
        {Math.floor(quantity).toLocaleString()}
      </p>
    </div>
  </div>
)

export default ProfileModal
