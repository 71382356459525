import { type FC, type HTMLAttributes } from 'react'

// const GalaIcon: FC<SVGProps<SVGSVGElement>> = (props) => (

const GalaIcon: FC<HTMLAttributes<HTMLSpanElement>> = ({
  className,
  ...props
}) => (
  <span
    {...props}
    className={`bg-gradient-to-br p-[1px] from-neutral-475 to-neutral-750 flex items-center justify-center rounded-full ${className ?? ''}`}
  >
    <span className="bg-neutral-925 w-full h-full flex items-center justify-center rounded-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 14"
        fill="none"
        role="img"
        aria-hidden="true"
        className="text-white w-1/2"
      >
        <path
          d="M4.75833 7.2918L5.98333 7.9918V11.9585L2.54167 9.97513V4.6668L1.375 3.9668V10.6168L7.15 13.9418V7.35013L4.75833 5.95013V7.2918Z"
          fill="currentColor"
        />
        <path
          d="M7.9082 7.35039V10.0921L9.07487 9.39206V7.99206L12.5165 6.00872V9.97539L7.9082 12.6587V14.0004L13.6832 10.6754V4.02539L7.9082 7.35039Z"
          fill="currentColor"
        />
        <path
          d="M8.72461 4.60833L7.49961 5.30833L4.05794 3.325L7.49961 1.34167L12.1079 4.025L13.2746 3.325L7.49961 0L1.72461 3.325L7.49961 6.65L9.89128 5.25L8.72461 4.60833Z"
          fill="currentColor"
        />
      </svg>
    </span>
  </span>
)

export default GalaIcon
