import { type FC } from 'react'

const SplashTitle: FC = () => (
  <>
    <h1 className="text-hotpink-700 font-figtree text-3xl mb-2">
      Treasure Hunter
    </h1>
    <div className="relative">
      <div
        aria-hidden="true"
        className="splash-title absolute top-0 left-0 w-full text-white [text-shadow:0_2px_0_var(--tw-shadow-color)] shadow-black/35 mix-blend-multiply z-[2] -translate-y-[2px]"
      >
        Play Bold
        <br />
        Win Big
      </div>
      <h2 className="relative splash-title text-transparent bg-titleGradient bg-clip-text pb-0.5">
        Play Bold
        <br />
        Win Big
      </h2>
    </div>
    <p className="font-bold mt-2.5">Unlock your fortune with $GALA.</p>
  </>
)

export default SplashTitle
