export enum UserType {
  GalaPlatformGameJwt = 'GalaPlatformGameJwt',
  AnonymousUser = 'AnonymousUser',
}

export interface IUserProfile {
  avatar: string
  displayName: string
  type: UserType
}

export interface IDrawing {
  id: number
  status: 'completed' | 'pending'
  startDate: string
  endDate: string
  winnerTiers: Array<{
    numbersMatched: number
    numWinners: number
    actualTotalGalaReward: number
  }>
  winningNumbers: number[]
}

export interface ICurrentDrawing {
  id: number
  burnAmount: number
  entryCount: number
  potSize: number
  endDate: Date
}

export interface IAllDrawingEntry {
  drawing: number
  id: number
  rewardAmount: number
  numbers: number[]
  winningNumbers: number[]
}

export interface IDrawingEntry {
  id: number
  numWinningNumber: number
  rewardAmount: number
  numbers: number[]
  winningNumbers: number[]
}

export interface IUserInventoryItem {
  itemId: string
  totalQuantity: number
  useableQuantity: number
}

export type UserInventory = IUserInventoryItem[]

export interface ITimeRemaining {
  days: number
  hours: number
  minutes: number
}

export type PageId = 'main' | 'tickets'
