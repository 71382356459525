import { type FC } from 'react'
import LottoMarqueeLights from './LottoMarqueeLights'
import LottoMarqueeNumbers from './LottoMarqueeNumbers'
import PowerPlayBodySvg from './PowerPlayBodySvg'
import PowerPlayTopSvg from './PowerPlayTopSvg'

const PowerPlay: FC<{ potSize?: number }> = ({ potSize }) => {
  return (
    <div className="relative z-1">
      <span className="sr-only">Power Play</span>
      <PowerPlayTopSvg className="relative z-[3] block mx-auto w-[55.77%] [filter:drop-shadow(0px_5.755px_5.755px_rgba(0,0,0,0.35))] mb-[-5%]" />
      <div className="relative">
        <PowerPlayBodySvg className="w-[1000px] max-w-full h-auto [filter:drop-shadow(0px_0px_48px_rgba(217,254,8,0.48))]" />
        <div className="absolute z-[2] left-0 bottom-0 h-full w-full p-[9%]">
          <LottoMarqueeNumbers potSize={potSize} maxDigits={8} />
        </div>
        <div className="w-[1000px] max-w-full absolute bottom-0 left-0">
          <LottoMarqueeLights />
        </div>
      </div>
    </div>
  )
}

export default PowerPlay
