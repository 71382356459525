import { type FC, useId } from 'react'
import { themeColors } from 'src/constants'

const SplashGradientTop: FC = () => {
  const gradientId = useId()
  const filterId = useId()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="513"
      height="347"
      viewBox="0 0 513 347"
      fill="none"
    >
      <g className="mix-blend-screen" filter={`url(#${filterId})`}>
        <path
          d="M119 119L159.311 196.388L393.84 227.569L353.53 150.182C207.26 188.505 119 119 119 119Z"
          fill={`url(#${gradientId})`}
        />
      </g>
      <defs>
        <filter
          id={filterId}
          x="0.155289"
          y="0.155289"
          width="512.53"
          height="346.259"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="59.4224"
            result="effect1_foregroundBlur_0_3"
          />
        </filter>
        <linearGradient
          id={gradientId}
          x1="429.925"
          y1="218.352"
          x2="367.022"
          y2="85.5339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={themeColors.hotpink[400]} />
          <stop offset="1" stopColor={themeColors.hotpink[650]} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SplashGradientTop
