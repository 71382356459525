import { type FC, useId } from 'react'

const NormalPlayBodySvg: FC<{ className?: string }> = ({ className }) => {
  const gradientId = useId()
  const filterId1 = useId()
  const filterId2 = useId()
  const filterId3 = useId()
  return (
    <svg
      className={`rounded-[10%] ${className}`}
      viewBox="0 0 512 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <g filter={`url(#${filterId1})`}>
        <rect
          x="3.23389"
          y="3.72632"
          width="504.975"
          height="178.905"
          rx="23.0846"
          fill="white"
          fillOpacity="0.2"
        />
        <g filter={`url(#${filterId2})`}>
          <rect
            x="11.8906"
            y="12.3831"
            width="487.662"
            height="161.592"
            rx="17.3134"
            fill="#0E0737"
            fillOpacity="0.8"
            shapeRendering="crispEdges"
          />
          <rect
            x="11.8906"
            y="12.3831"
            width="487.662"
            height="161.592"
            rx="17.3134"
            fill={`url(#${gradientId})`}
            fillOpacity="0.2"
            shapeRendering="crispEdges"
          />
          <rect
            x="12.612"
            y="13.1045"
            width="486.219"
            height="160.149"
            rx="16.592"
            stroke="white"
            strokeOpacity="0.05"
            strokeWidth="1.44279"
            shapeRendering="crispEdges"
          />
          <g filter={`url(#${filterId3})`}>
            <rect
              x="34.9751"
              y="35.4677"
              width="441.493"
              height="115.423"
              rx="11.5423"
              fill="white"
              fillOpacity="0.2"
            />
            <rect
              x="35.6965"
              y="36.1891"
              width="440.05"
              height="113.98"
              rx="10.8209"
              stroke="white"
              strokeOpacity="0.05"
              strokeWidth="1.44279"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id={filterId1}
          x="3.23389"
          y="3.72632"
          width="504.975"
          height="180.348"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.44279" />
          <feGaussianBlur stdDeviation="1.44279" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7_13"
          />
        </filter>
        <filter
          id={filterId2}
          x="0.348336"
          y="0.840829"
          width="510.747"
          height="184.677"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.77114" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0696181 0 0 0 0 0.556945 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7_13"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7_13"
            result="shape"
          />
        </filter>
        <filter
          id={filterId3}
          x="34.9751"
          y="35.4677"
          width="441.493"
          height="116.866"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.44279" />
          <feGaussianBlur stdDeviation="1.44279" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7_13"
          />
        </filter>
        <linearGradient
          id={gradientId}
          x1="12.8004"
          y1="12.3831"
          x2="461.096"
          y2="248.664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008CFF" />
          <stop offset="0.595" stopColor="#DBFF00" />
          <stop offset="1" stopColor="#B300FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default NormalPlayBodySvg
