import React, { FC, useEffect, useRef } from "react"

// Copied from https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
function useOutsideAlerter(ref: React.RefObject<any>, close: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        close()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, close]);
}

export const OverlayContainer: FC<{
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}> = ({ isOpen, onClose, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClose);

  if (!isOpen) return null

  return (
    <div className="max-w-lg left-1/2 w-full absolute bottom-0 bg-opacity-50 flex items-center justify-center z-10 rounded-t-3xl" style={{
      translate: '-50%',
    }}>
      <div ref={wrapperRef} className="relative bg-lottoblue-600 bg-opacity-25 backdrop-blur-lg px-5 py-2 rounded-t-3xl shadow-lg w-full overflow-y-auto max-h-screen">
        <button
          className="lotto-close-button"
          aria-label="Close overlay"
          onClick={onClose}
        >
          ✕
        </button>
        {children}
      </div>
    </div>
  )
}