import { type FC, useEffect, useRef, useState } from 'react'
import { globalNotifications } from 'src/notifications'
import styles from './lottoMarquee.module.css'

interface IPotSizeData {
  currentValue: string
  previousValue: string
  changed: boolean
}

const LottoMarquee: FC<{ potSize?: number; maxDigits?: number }> = ({
  potSize,
  maxDigits = 7,
}) => {
  const prevPotSize = useRef<number>(0)
  const [newPotSize, setNewPotSize] = useState(0)
  const potSizeData = useRef<IPotSizeData[]>(
    new Array(maxDigits).fill({
      currentValue: '0',
      previousValue: '0',
      changed: false,
    })
  )

  useEffect(() => {
    const handler = async () => {
      setNewPotSize(Math.floor(potSize ?? 0))
    }
    if (potSize !== prevPotSize.current) {
      handler()
    }
    globalNotifications.on('potSizeChanged', handler)
    return () => globalNotifications.off('potSizeChanged', handler)
  }, [prevPotSize, newPotSize, setNewPotSize, potSize])

  useEffect(() => {
    const padAmount = Math.max(maxDigits, newPotSize.toString().length)
    const previousValueFormatted = (prevPotSize.current ?? 0)
      .toString()
      .padStart(padAmount, '0')
    const currentValueFormatted = (newPotSize ?? 0)
      .toString()
      .padStart(padAmount, '0')
    potSizeData.current = currentValueFormatted.split('').map((char, index) => {
      const reverseIndex = -index + currentValueFormatted.length - 1
      const diffLength = (newPotSize - (prevPotSize.current ?? 0)).toString()
        .length
      const prevChar = previousValueFormatted?.[index] ?? '0'
      return {
        currentValue: char,
        previousValue: prevChar,
        changed: diffLength > reverseIndex || prevChar !== char,
      }
    })

    prevPotSize.current = newPotSize
  }, [newPotSize, maxDigits])

  useEffect(() => {
    const resetNumbers = setTimeout(() => {
      potSizeData.current.forEach((data) => {
        data.changed = false
      }, 4000)
    })

    return () => clearTimeout(resetNumbers)
  }, [potSizeData])

  return (
    <div className="w-full h-full flex justify-stretch gap-1 sm:gap-1.5">
      {potSizeData.current.map((data: IPotSizeData, index) => (
        <div
          key={index}
          className={`${styles.marqueeNumber} ${(index - potSizeData.current.length) % 3 === 0 ? 'ml-1' : ''} ${
            potSizeData.current.length <= 7
              ? 'text-4xl sm:text-[2.75rem]'
              : 'text-3xl sm:text-4xl'
          }`}
        >
          <p
            style={{
              animationDelay: `${(-index + potSizeData.current.length) / 4 + 1.1}s`,
            }}
            className={`current-number ${data.changed ? styles.anim3 : ''}`}
          >
            {data.currentValue}
          </p>
          <p
            style={{
              animationDelay: `${(-index + potSizeData.current.length) / 4 + 0.25}s`,
            }}
            className={`${styles.questionMark} ${data.changed ? styles.anim2 : ''}`}
          >
            ?
          </p>
          <p
            style={{
              animationDelay: `${(-index + potSizeData.current.length) / 4}s`,
            }}
            className={`${styles.prevNumber} ${data.changed ? styles.anim1 : ''}`}
          >
            {data.previousValue}
          </p>
        </div>
      ))}
    </div>
  )
}

export default LottoMarquee
