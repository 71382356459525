import { type FC } from 'react'
import htp1 from '../assets/images/how-to-play-1.png'
import htp2 from '../assets/images/how-to-play-2.svg'
import htp4 from '../assets/images/how-to-play-4.svg'
import NextDrawingCountdown from './NextDrawCountdown'
import { footerLinks } from 'src/constants'

const HowToPlayCards: FC<{
  timeRemaining: { days: number; hours: number; minutes: number }
}> = ({ timeRemaining }) => {
  const cards = {
    card1: {
      headline: 'Get tickets for using $GALA',
      subheadline: 'Get Tickets',
      image: htp1,
    },
    card2: {
      headline: 'Choose your numbers*',
      subheadline: 'Play Now',
      image: htp2,
    },
    card3: {
      headline: 'Wait for the draw',
      subheadline: 'See upcoming draws',
    },
    card4: {
      headline: 'Check for prizes',
      subheadline: 'See Results',
      image: htp4,
    },
  }

  const cardClasses =
    'bg-white bg-opacity-10 w-full h-full rounded-xl relative overflow-hidden flex flex-col items-start justify-start'

  return (
    <section className="how-to-play w-full py-6">
      <h2 className="text-2xl font-bold text-white text-left mb-8">
        How To Play
      </h2>
      <div className="carousel-container mt-8 w-full h-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 justify-start gap-8 w-full h-full pb-8">
          {/* step1 */}
          <div className={`${cardClasses}`}>
            <div className="relative z-10 px-6 pt-6">
              <h2 className="text-white text-3xl font-bold text-left w-full leading-8 mb-1">
                {cards.card1.headline}
              </h2>
              <p className="text-lottoblue-400 text-super-tiny text-left">
                or AMOE option{' '}
                <a
                  className="text-lottoblue-600 underline hover:no-underline"
                  href={footerLinks.terms}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </p>
              <h3 className="text-lottogreen text-xl font-semibold mt-2 text-left w-full">
                {cards.card1.subheadline}
              </h3>
            </div>
            <div className={`w-full h-auto min-h-56 relative z-0`}>
              <img
                src={cards.card1.image}
                alt="Background"
                className={`w-full object-cover pointer-events-none`}
              />
            </div>
          </div>

          {/* step2 */}
          <div className={`${cardClasses}`}>
            <div className="relative z-10 px-6 pt-6">
              <h2 className="text-white text-3xl font-bold text-left w-full leading-8">
                {cards.card2.headline}
              </h2>
              <h3 className="text-lottogreen text-xl font-semibold mt-2 text-left w-full">
                {cards.card2.subheadline}
              </h3>
            </div>
            <div className="w-full h-auto min-h-56 relative z-0 -mt-10">
              <img
                src={cards.card2.image}
                alt="Background"
                className={`w-full object-cover pointer-events-none`}
              />
            </div>
          </div>

          {/* step3 */}
          <div className={`${cardClasses}`}>
            <div className="relative z-10 px-6 pt-6">
              <h2 className="text-white text-3xl font-bold text-left w-full leading-8">
                {cards.card3.headline}
              </h2>
              <h3 className="text-lottogreen text-xl font-semibold mt-2 text-left w-full">
                {cards.card3.subheadline}
              </h3>
            </div>
            <div className="w-full px-6 big-shadow-down transform translate-y-8 mb-8">
              <NextDrawingCountdown
                timeRemaining={timeRemaining}
                forceColumn={true}
              />
            </div>
          </div>

          {/* step4 */}
          <div className={`${cardClasses}`}>
            <div className="relative z-10 px-6 pt-6">
              <h2 className="text-white text-3xl font-bold text-left w-full leading-8">
                {cards.card4.headline}
              </h2>
              <h3 className="text-lottogreen text-xl font-semibold mt-2 text-left w-full">
                {cards.card4.subheadline}
              </h3>
            </div>
            <div className="w-full h-auto min-h-56 relative z-0">
              <img
                src={cards.card4.image}
                alt="Background"
                className={`w-full object-cover pointer-events-none`}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`rules grid grid-cols-1 xs:grid-cols-2 gap-y-12 gap-x-4 items-start justify-center mt-3`}
      >
        <div className="flex flex-col items-start justify-start w-full gap-4">
          <h2 className="text-xl font-bold flex flex-row items-center justify-start gap-3">
            <i
              aria-hidden="true"
              className="block w-1 h-6 rounded-full bg-blueishpurple-700"
            ></i>
            Winning Criteria
          </h2>
          <p className="mb-8 text-lottoblue-400">
            The digits on your ticket must match the numbers pulled from the
            draw
          </p>
          <p className="text-sm text-lottoblue-400">
            *10 $GALA fee per ticket entered
          </p>
        </div>

        <div className="flex flex-col items-start justify-start w-full gap-4">
          <h2 className="text-xl font-bold flex flex-row items-center justify-start gap-3">
            <i
              aria-hidden="true"
              className="block w-1 h-6 rounded-full bg-lottogreen"
            ></i>
            Prize Fund
          </h2>
          <ul className="text-lottoblue-400 ml-6 list-disc">
            <li className="py-1">
              Burning of GALA from activity on the Gala ecosystem
            </li>
            <li className="py-1">Rollover Prizes</li>
            <li className="py-1">Gala injections</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default HowToPlayCards
