import { type FC } from 'react'
import LottoMarqueeNormalPlay from './NormalPlay'
import LottoMarqueePowerPlay from './PowerPlay'

const LottoMarquee: FC<{ potSize?: number; powerPlay?: boolean }> = ({
  potSize,
  powerPlay,
}) => {
  return (
    <>
      {powerPlay && <LottoMarqueePowerPlay potSize={potSize} />}
      {!powerPlay && <LottoMarqueeNormalPlay potSize={potSize} />}
    </>
  )
}

export default LottoMarquee
