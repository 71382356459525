import { type FC, useState } from 'react'
import galaLottoLogo from 'src/assets/images/gala-lotto-logo.svg'
import { useAuth } from 'src/AuthContext'
import Avatar from 'src/User/Avatar'
import AvatarLoading from 'src/User/AvatarLoading'
import ProfileModal from './Dialog/ProfileModal'
import { UserType } from './types'
import { globalEvents } from './events'

const Header: FC = () => {
  const { logIn, isPendingProfile, userProfile } = useAuth()

  const [isProfileVisible, setIsProfileVisibleState] = useState(false)

  const isAnonymousUser = userProfile?.type === UserType.AnonymousUser

  const setIsProfileVisible = (isVisible: boolean) => {
    setIsProfileVisibleState(isVisible)

    globalEvents.emit('navigate', 'profile')
  }

  const showProfile = () => {
    setIsProfileVisible(true)
  }

  const hideProfile = () => {
    setIsProfileVisible(false)
  }

  const handleAvatarClick = () => {
    if (isAnonymousUser) {
      logIn()
    } else {
      showProfile()
    }
  }

  return (
    <>
      <div className="header text-white w-full">
        <div className="py-2 px-5 flex justify-between items-center gap-2 xs:gap-4 sm:gap-6">
          <div className="flex-grow-0 flex-shrink-0 flex gap-2 items-center py-1">
            <img
              src={galaLottoLogo}
              className="w-8 h-8"
              alt=""
              width="32"
              height="32"
            />
            <span className="sr-only">Gala</span>
            <span className="bg-gradient-to-r from-lottogreen to-70% to-white bg-clip-text text-transparent uppercase font-bold text-xs xs:text-sm tracking-[0.25em]">
              Sweepstakes
            </span>
          </div>
          <div className="flex items-center relative">
            <div
              className={`rounded-full overflow-hidden h-10 ${isPendingProfile ? 'w-10' : 'transition-all w-[6.25rem]'}`}
            >
              <AvatarLoading
                className={`w-10 h-10 transition ${isPendingProfile ? '' : 'absolute opacity-0'}`}
              />
              {!isPendingProfile && isAnonymousUser && (
                <button
                  className={`relative transition-color duration-200 bg-white text-black hover:bg-lottogreen rounded-full font-semibold text-sm leading-4 py-3 px-4`}
                  onClick={logIn}
                >
                  <span
                    className={`transition whitespace-nowrap ${isPendingProfile ? 'opacity-0' : ''}`}
                  >
                    <span className="hidden xs:inline">Gala</span> Login
                  </span>
                </button>
              )}
              {!isPendingProfile && !isAnonymousUser && (
                <button
                  aria-label={isAnonymousUser ? 'Login' : 'My Profile'}
                  className="relative rounded-full transition block ml-auto mr-0"
                  onClick={handleAvatarClick}
                >
                  <Avatar className="w-10 h-10" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProfileModal open={isProfileVisible} onClose={hideProfile} />
    </>
  )
}

export default Header
