import { type FC } from 'react'
import Logo from 'src/assets/images/egs-logo.png'
import powerPlayBg from 'src/assets/images/powerplaybg-min.webp'
import { footerLinks } from 'src/constants'
import { type ITimeRemaining } from 'src/types'
import LottoMarquee from './LottoMarquee'
import NextDrawCountdown from 'src/UIComponents/NextDrawCountdown'

interface IProps {
  potSize: number
  timeRemaining: ITimeRemaining
}

const LottoMarqueeCountdown: FC<IProps> = ({ potSize, timeRemaining }) => {
  const isPowerPlay = potSize >= 10000000
  return (
    <div className="text-center w-full relative max-w-[450px]">
      {/* blurcles */}
      <div className="w-64 sm:w-96 h-64 rounded-full bg-[#00FFFF] filter blur-3xl absolute left-0 top-0 -z-0 pointer-events-none opacity-20"></div>
      <div className="w-32 sm:w-48 h-48 rounded-full bg-[#FF21F8] filter blur-3xl absolute right-0 top-6 -z-0 pointer-events-none opacity-20"></div>
      {isPowerPlay && (
        <div className="w-[150%] sm:w-[180%] mix-blend-screen absolute top-0 left-1/2 -translate-x-1/2">
          <img
            src={powerPlayBg}
            alt=""
            width="798"
            height="532"
            className="w-full mix-blend-screen h-auto"
          />
        </div>
      )}
      <div className="epic-logo flex flex-col justify-center">
        <img
          src={Logo}
          alt="Epic Gala Sweepstakes"
          width="400"
          height="148"
          className="w-full max-w-[768px] mx-auto h-auto relative z-0 mb-4"
        />
      </div>
      <div className="flex justify-center gap-1 text-4xl font-bold z-10 relative">
        <LottoMarquee potSize={potSize} powerPlay={isPowerPlay} />
      </div>
      <NextDrawCountdown timeRemaining={timeRemaining} />
      <p className="text-white text-sm sm:text-base font-bold">
        Free ticket* with every{' '}
        <span className="text-lottogreen">100 $GALA</span> burn
      </p>
      <p className="text-lottoblue-400 text-super-tiny text-center mb-8">
        or AMOE option{' '}
        <a
          className="text-lottoblue-600 underline hover:no-underline"
          href={footerLinks.terms}
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
      </p>
    </div>
  )
}

export default LottoMarqueeCountdown
