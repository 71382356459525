import { type FC, useEffect, useState } from 'react'
import { AuthProvider } from 'src/AuthContext'
import { GameProvider } from 'src/Game/GameContext'
import GameScreen from 'src/Game/GameScreen'
import LoginSplashScreen from 'src/Dialog/LoginSplashScreen'
import './galaAnalytics'
import LottoWidget from './UIComponents/LottoWidget'

const App: FC = () => {
  const [backgroundImage, setBackgroundImage] = useState('')
  const pathname = window.location.pathname

  useEffect(() => {
    const updateBackgroundImage = () => {
      const streakBgImage = getComputedStyle(document.documentElement)
        .getPropertyValue('--streak-bg-image')
        .trim()
      setBackgroundImage(`${streakBgImage}`)
    }

    updateBackgroundImage()

    const handleVariableChange = () => updateBackgroundImage()
    document.addEventListener('cssVariableChange', handleVariableChange)

    return () =>
      document.removeEventListener('cssVariableChange', handleVariableChange)
  }, [])

  return (
    <div
      className={`${backgroundImage} app flex-grow flex flex-col w-full items-center min-h-full h-svh`}
    >
      <AuthProvider>
        { 
          /countdown/.test(pathname) ? <LottoWidget refreshInterval={60000} /> : <AppInner />
        }
      </AuthProvider>
    </div>
  )
}

const AppInner: FC = () => {
  return (
    <>
        {/* <SoundProvider> */}
          <GameProvider>
            <GameScreen />
          </GameProvider>
        {/* </SoundProvider> */}
      <LoginSplashScreen />
    </>
  )
}

export default App
