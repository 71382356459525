import {
  type ButtonHTMLAttributes,
  type FC,
  type MouseEventHandler,
  type ReactNode,
} from 'react'

interface ButtonPrimaryProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const ButtonPrimary: FC<ButtonPrimaryProps> = ({
  children,
  className,
  ...props
}) => (
  <button
    {...props}
    className={`relative group disabled:grayscale disabled:opacity-70 ${className ?? ''}`}
  >
    <span className="block shadow-ctaBottom shadow-[#0e0e0e] w-full absolute left-0 bottom-0 h-full rounded-xl"></span>
    <span className="block relative p-4 text-white text-shadow-sm bg-vividpurple-700 border-pinkishpurple-700 border-2 font-figtree rounded-xl text-lg leading-none shadow-cta w-full transition group-hover:shadow-transparent group-hover:translate-y-0.5 group-disabled:group-hover:shadow-cta group-disabled:group-hover:translate-y-0">
      {children}
    </span>
  </button>
)

export default ButtonPrimary
