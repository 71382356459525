import { type FC, type ReactNode } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  type DialogProps,
} from '@headlessui/react'

interface ResponsiveDialogProps extends DialogProps {
  children: ReactNode
  dialogTitle?: ReactNode
}

const ResponsiveDialog: FC<ResponsiveDialogProps> = ({
  children,
  dialogTitle,
  ...props
}) => (
  <Dialog {...props} className="relative z-20">
    <DialogBackdrop
      transition
      className="fixed inset-0 bg-lottoblue-700/40 backdrop-blur-sm transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
    />
    <div className="fixed flex-grow flex flex-col items-center inset-0 z-20 w-screen overflow-y-auto sm:justify-center">
      <DialogPanel
        transition
        className="relative flex-grow flex flex-col items-center justify-center w-full transform bg-lottoblue-900/50 shadow-lg ring-2 ring-white/10 backdrop-blur-2xl transition-all sm:flex-grow-0 sm:max-w-md sm:rounded-[3rem] data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="p-6 relative flex-grow w-full max-w-xl overflow-hidden">
          <div className="relative">
            {dialogTitle && (
              <DialogTitle
                as="h3"
                className="font-figtree text-3xl tracking-tight text-left my-10"
              >
                {dialogTitle}
              </DialogTitle>
            )}
            {children}
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
)

export default ResponsiveDialog
